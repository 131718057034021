import { 
	Component, 
	Input, 
	OnInit, 
	ViewChild, 
} from "@angular/core";

import { formatDate } from "@angular/common";

import { Router } from "@angular/router"

import { ModalController } from "@ionic/angular";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";

import { TranslateService } from "@ngx-translate/core";

import { Utils } from "../utils";

import { AppSettings } from "../app-settings";

import { ActionService } from "../services/action.service";
import { UserService } from "../services/user.service";

import { ActionScheduleComponent } from "./action-schedule.component";
import { AddActionsPage } from "../add-actions/add-actions.page";

@Component({
	selector: "action-detail",
	templateUrl: "./action-detail.component.html",
	styleUrls: ["./action-detail.component.css"]
})
export class ActionDetailComponent implements OnInit {

	private UTILS: any = Utils;

	@Input() action: any = null;
	@Input() subscribed_action: any = null;
	@Input() parent: any = null;

	private LOCALIZATION_PREFIX: string = "action_detail.";

	private APP_SETTINGS = AppSettings

	private waiting_for_server_response: boolean = false;

	private modal: any = null;

	private dow_strs: Array<string> = [];

	private is_event_str: string = null;
	private is_one_time_str: string = null;
	private is_daily_str: string = null;
	private is_weekly_str: string = null;
	private is_monthly_str: string = null;
	private is_schedule_overridable_str: string = null;

	private scheduled_date_str: string = null;
	private start_date_str: string = null;
	private end_date_str: string = null;

	private dow_str: string = null;
	private dom_str: string = null;

	constructor(
		private action_service: ActionService, 
		private in_app_browser: InAppBrowser, 
		private modal_controller: ModalController, 
		private router: Router, 
		private translate_service: TranslateService, 
		private user_service: UserService, 
	) {
		// 
	}

	ngOnInit(): void {

		for (var i = 0; i < 7; i ++) {
			this.translate_service.get("dow_" + i).subscribe((res: string) => {
			    this.dow_strs.push(res);
			});
		}

		this.translate_service.get(this.LOCALIZATION_PREFIX + "is_event").subscribe((res: string) => {
		    this.is_event_str = res;
		});
		this.translate_service.get(this.LOCALIZATION_PREFIX + "is_one_time").subscribe((res: string) => {
		    this.is_one_time_str = res;
		});
		this.translate_service.get(this.LOCALIZATION_PREFIX + "is_daily").subscribe((res: string) => {
		    this.is_daily_str = res;
		});
		this.translate_service.get(this.LOCALIZATION_PREFIX + "is_weekly").subscribe((res: string) => {
		    this.is_weekly_str = res;
		});
		this.translate_service.get(this.LOCALIZATION_PREFIX + "is_monthly").subscribe((res: string) => {
		    this.is_monthly_str = res;
		});
		this.translate_service.get(this.LOCALIZATION_PREFIX + "is_schedule_overridable").subscribe((res: string) => {
		    this.is_schedule_overridable_str = res;
		});

		this.build_date_strs();

		this.translate_service.get(this.LOCALIZATION_PREFIX + "dow").subscribe((res: string) => {
		    this.dow_str = res;
		});
		this.translate_service.get(this.LOCALIZATION_PREFIX + "dom").subscribe((res: string) => {
		    this.dom_str = res;
		});

	}

	private get_language(): string {
		return navigator.language;
	}

	private get_percentage_participation_str(): string {
		return Math.round(this.action.num_participants / this.action.of_possible_participants * 100).toLocaleString() + "%";
	}

	private get_action_num_points_str(): string {
		return Math.round(this.action.num_points).toLocaleString();
	}

	private build_date_strs(): void {
		if (this.subscribed_action && this.subscribed_action.scheduled_date) {
			this.translate_service.get(this.LOCALIZATION_PREFIX + "scheduled_date", {date: formatDate(this.subscribed_action.scheduled_date, "MMMM d, yyyy", navigator.language)}).subscribe((res: string) => {
			    this.scheduled_date_str = res;
			});
		} else if (this.action.scheduled_date) {
			this.translate_service.get(this.LOCALIZATION_PREFIX + "scheduled_date", {date: formatDate(this.action.scheduled_date, "MMMM d, yyyy", navigator.language)}).subscribe((res: string) => {
			    this.scheduled_date_str = res;
			});
		}
		if (this.subscribed_action && this.subscribed_action.start_date) {
			this.translate_service.get(this.LOCALIZATION_PREFIX + "start_date", {date: formatDate(this.subscribed_action.start_date, "MMMM d, yyyy", navigator.language)}).subscribe((res: string) => {
			    this.start_date_str = res;
			});
		} else if (this.action.start_date) {
			this.translate_service.get(this.LOCALIZATION_PREFIX + "start_date", {date: formatDate(this.action.start_date, "MMMM d, yyyy", navigator.language)}).subscribe((res: string) => {
			    this.start_date_str = res;
			});
		}
		if (this.subscribed_action && this.subscribed_action.end_date) {
			this.translate_service.get(this.LOCALIZATION_PREFIX + "end_date", {date: formatDate(this.subscribed_action.end_date, "MMMM d, yyyy", navigator.language)}).subscribe((res: string) => {
			    this.end_date_str = res;
			});
		} else if (this.action.end_date) {
			this.translate_service.get(this.LOCALIZATION_PREFIX + "end_date", {date: formatDate(this.action.end_date, "MMMM d, yyyy", navigator.language)}).subscribe((res: string) => {
			    this.end_date_str = res;
			});
		}
	}

	async customize_schedule() {
		this.modal = await this.modal_controller.create({
			component: ActionScheduleComponent,
			componentProps: {
				action: this.action, 
		    	parent: this, 
		    }, 
			cssClass: "action_schedule", 
		});
		return await this.modal.present();
	}
	set_subscribed_action(subscribed_action: any): void {
		this.subscribed_action = subscribed_action;
		this.build_date_strs();
		if (this.parent.notify_listeners) this.parent.notify_listeners();
	}
	dismiss_modal(): void {
		if (this.modal) this.modal.dismiss().then(() => { this.modal = null; });
	}

	private dismiss_this_modal(): void {
		if (this.parent) this.parent.dismiss_modal();
	}

	private toggle_action_liked(): void {
		(this.parent as any).toggle_action_liked(this.action);
	}

	private toggle_action_subscribed(): void {
		this.waiting_for_server_response = true;
		let _this = this;
		(this.parent as any).toggle_action_subscribed(this.action, function() {
			_this.waiting_for_server_response = false;
		});
	}

	private get_scheduling_strings(): Array<string> {

		var strs = [];

		if (this.action.is_event == 1) strs.push(this.is_event_str);

		if ((this.subscribed_action && this.subscribed_action.schedule_type == "one_time") || this.action.schedule_type == "one_time") {

			strs.push(this.is_one_time_str);
			if ((this.subscribed_action && this.subscribed_action.scheduled_date) || (!this.subscribed_action && this.action.scheduled_date)) strs.push(this.scheduled_date_str);

		} else if ((this.subscribed_action && this.subscribed_action.schedule_type == "daily") || this.action.schedule_type == "daily") {

			strs.push(this.is_daily_str);
			if ((this.subscribed_action && this.subscribed_action.start_date) || (!this.subscribed_action && this.action.start_date)) strs.push(this.start_date_str);
			if ((this.subscribed_action && this.subscribed_action.end_date) || (!this.subscribed_action && this.action.end_date)) strs.push(this.end_date_str);
			if (this.action.is_schedule_overridable == 1) strs.push(this.is_schedule_overridable_str);

		} else if (((this.subscribed_action && this.subscribed_action.schedule_type == "weekly") || this.action.schedule_type == "weekly") && this.dow_strs.length == 7) {

			strs.push(this.is_weekly_str);
			var dow;
			if (this.subscribed_action && this.subscribed_action.dow) {
				dow = JSON.parse(this.subscribed_action.dow);
			} else {
				dow = JSON.parse(this.action.dow);
			}
			if (dow.length > 0) {
				for (var _ = 0; _ < dow.length; _ ++) {
					dow[_] = this.dow_strs[dow[_]];
				}
			}
			if (this.dow_str) strs.push(this.dow_str + " " + dow.join(", "));
			if ((this.subscribed_action && this.subscribed_action.start_date) || (!this.subscribed_action && this.action.start_date)) strs.push(this.start_date_str);
			if ((this.subscribed_action && this.subscribed_action.end_date) || (!this.subscribed_action && this.action.end_date)) strs.push(this.end_date_str);
			if (this.action.is_schedule_overridable == 1) strs.push(this.is_schedule_overridable_str);

		} else if ((this.subscribed_action && this.subscribed_action.schedule_type == "monthly") || this.action.schedule_type == "monthly") {

			strs.push(this.is_monthly_str);
			var dom;
			if (this.subscribed_action && this.subscribed_action.dom) {
				dom = JSON.parse(this.subscribed_action.dom);
			} else {
				dom = JSON.parse(this.action.dom);
			}
			if (dom.length > 0) {
				for (var _ = 0; _ < dom.length; _ ++) {
					dom[_] ++;
				}
			}
			if (this.dom_str) strs.push(this.dom_str + " " + dom.join(", "));
			if ((this.subscribed_action && this.subscribed_action.start_date) || (!this.subscribed_action && this.action.start_date)) strs.push(this.start_date_str);
			if ((this.subscribed_action && this.subscribed_action.end_date) || (!this.subscribed_action && this.action.end_date)) strs.push(this.end_date_str);
			if (this.action.is_schedule_overridable == 1) strs.push(this.is_schedule_overridable_str);

		}

		for (var i = 0; i < strs.length; i ++) {
			if (strs[i] == null) {
				strs.splice(i, 1);
				i --;
			}
		}
		return strs;

	}

}


