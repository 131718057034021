import { IonSlides } from "@ionic/angular";

import { TranslateService } from "@ngx-translate/core";

export abstract class DualSliderPage {

	abstract LOCALIZATION_PREFIX: string;

	abstract NAV_ITEMS: Array<any>;
	abstract nav_slides: IonSlides;
	abstract main_slides: IonSlides;

	abstract tabs_header_collapsible: boolean;
	abstract tabs_header_collapsed: boolean;

	abstract INIT_BANNER_HEIGHT: number;
	abstract banner_height: number;

	abstract INIT_MAIN_SLIDES_HEIGHT_ADJ: number;
	abstract main_slides_height_adj: number;

	abstract MAIN_SLIDER_SPEED;

	abstract last_nav_index;

	constructor(
		private _translate_service: TranslateService, 
	) {
		// 
	}

	public slide_to(slide_index: number): void {
		this.nav_slides.slideTo(slide_index, 0, false);
		this.main_slides.slideTo(slide_index, 0, false);
	}

	translate_nav_labels(): void {
		for (var i = 0; i < this.NAV_ITEMS.length; i ++) {
			let item = this.NAV_ITEMS[i];
			for (const prop in item) {
				if (prop.indexOf("label_") == 0) {
					this._translate_service.get(this.LOCALIZATION_PREFIX + this.NAV_ITEMS[i][prop]).subscribe((res: string) => {
					    this.NAV_ITEMS[i][prop] = res;
					});
				}
			}
		}
	}

	// Hack to deal with duplicate slides:
	update_duplicate_slides(): void {
		let _this = this;
		setTimeout(function() {
			for (var i = 0; i < _this.NAV_ITEMS.length; i ++) {
				let slides = document.body.querySelectorAll('#main_slides *[data-swiper-slide-index="' + i + '"]');
				var html;
				for (var j = 0; j < slides.length; j ++) {
					if (!slides[j].classList.contains("swiper-slide-duplicate")) {
						html = slides[j].innerHTML;
						break;
					}
				}
				for (var j = 0; j < slides.length; j ++) {
					if (slides[j].classList.contains("swiper-slide-duplicate")) {
						slides[j].innerHTML = html;
					}
				}
			}
		}, 0);
	}

	
	nav_slide_touch_start(): void {
		this.ignore_main_slide_change = true;
	}
	nav_slide_drag(): void {
		this.nav_slides.getActiveIndex().then(current_nav_index => {
			if (this.last_nav_index % this.NAV_ITEMS.length == current_nav_index % this.NAV_ITEMS.length) {
				this.last_nav_index = current_nav_index;
				return;
			}
			this.main_slides.getActiveIndex().then(current_main_index => {
				let current_main_index_adj = current_main_index - 1;
				let diff = Math.abs(current_nav_index % this.NAV_ITEMS.length - current_main_index_adj % this.NAV_ITEMS.length);
				if (current_nav_index == this.last_nav_index + 1 && (diff == 1 || diff == this.NAV_ITEMS.length - 1)) {
					this.main_slides.slideNext();
				} else if (current_nav_index == this.last_nav_index - 1 && (diff == 1 || diff == this.NAV_ITEMS.length - 1)) {
					this.main_slides.slidePrev();
				} else if (diff > 0) {
					this.main_slides.slideTo((current_nav_index + 1) % this.NAV_ITEMS.length);
				}
				this.last_nav_index = current_nav_index;
			});
		});
	}
	nav_slide_tap(): void {
		this.match_main_slide_to_nav_slide();
	}
	nav_slide_transition_end(): void {
		this.match_main_slide_to_nav_slide();
	}
	match_main_slide_to_nav_slide(): void {
		this.nav_slides.getActiveIndex().then(current_nav_index => {
			this.main_slides.getActiveIndex().then(current_main_index => {
				let _this = this;
				setTimeout(function() {
					let current_main_index_adj = current_main_index - 1;
					let diff = Math.abs(current_nav_index % _this.NAV_ITEMS.length - current_main_index_adj % _this.NAV_ITEMS.length);
					if (current_nav_index == _this.last_nav_index + 1 && (diff == 1 || diff == _this.NAV_ITEMS.length - 1)) {
						_this.main_slides.slideNext();
					} else if (current_nav_index == _this.last_nav_index - 1 && (diff == 1 || diff == _this.NAV_ITEMS.length - 1)) {
						_this.main_slides.slidePrev();
					} else if (diff > 0) {
						_this.main_slides.slideTo((current_nav_index + 1) % _this.NAV_ITEMS.length);
					}
					_this.last_nav_index = current_nav_index;
				}, 0);
			});
		});
	}

	banner_hidden = false;
	private scrollTop_main_slide_last: number = 0;
	private ignore_scroll_up: boolean = false; // So the banner collapse doesn't trigger a scroll up
	main_slide_content_scroll(ev: any): void {

		if (ev.target.scrollTop > this.scrollTop_main_slide_last) { // scroll down

			if (this.banner_hidden) return;

			if (this.tabs_header_collapsible) this.tabs_header_collapsed = true;

			this.banner_hidden = true;
			this.banner_height = 0;
			this.main_slides_height_adj -= this.INIT_BANNER_HEIGHT;

			this.ignore_scroll_up = true;
			let _this = this;
			setTimeout(function() { _this.ignore_scroll_up = false; }, 1200);

		} else if (ev.target.scrollTop == 0) { // scroll up

			if (this.ignore_scroll_up) return;

			if (!this.banner_hidden) return;

			if (this.tabs_header_collapsible) this.tabs_header_collapsed = false;

			this.banner_hidden = false;
			this.banner_height = this.INIT_BANNER_HEIGHT;
			this.main_slides_height_adj += this.INIT_BANNER_HEIGHT;

		}
		
		this.scrollTop_main_slide_last = ev.target.scrollTop;
		
	}

	ignore_main_slide_change: boolean = true;
	main_slide_touch_end(): void {
		this.ignore_main_slide_change = false;
	}
	main_slide_prev_start(): void {
		if (this.ignore_main_slide_change) {
			return;
		} else {
			this.ignore_main_slide_change = true;
		}
		this.nav_slides.slidePrev();
	}
	main_slide_next_start(): void {
		if (this.ignore_main_slide_change) {
			return;
		} else {
			this.ignore_main_slide_change = true;
		}
		this.nav_slides.slideNext();
	}
	// Hack to deal with duplicate slides:
	main_slide_transition_end(): void {
		this.main_slides.getActiveIndex().then(async current_main_index => {
			var swiper = await this.main_slides.getSwiper();
			swiper.params.speed = 0;
			if (current_main_index == 0) this.main_slides.slideTo(this.NAV_ITEMS.length, 0, false);
			if (current_main_index == this.NAV_ITEMS.length + 1) this.main_slides.slideTo(1, 0, false);
			let _this = this;
			setTimeout(function() { 
				swiper.params.speed = _this.MAIN_SLIDER_SPEED;
			}, 500);

			if ((this as any).on_slide_change) (this as any).on_slide_change(current_main_index);
		});
	}

}


