import { HttpHeaders } from "@angular/common/http";

export class AppSettings {

    public static WEB_ROOT = "https://ptagh-api.nkromsdavis.net/";
	public static API_ENDPOINT = AppSettings.WEB_ROOT + "api/";
    public static POST_HEADERS = new HttpHeaders().set("Content-Type", "application/json");

    public static ACTION_IMAGES_PATH = AppSettings.WEB_ROOT + "action_images/";
    public static BUSINESS_IMAGES_PATH = AppSettings.WEB_ROOT + "business_images/";
    public static HEADLINE_IMAGES_PATH = AppSettings.WEB_ROOT + "headline_images/";

    // public static PHOTO_PATH = AppSettings.WEB_ROOT + "photos/";

	public static format_date_for_SQL(date) {
	    var month = "" + (date.getMonth() + 1),
	        day = "" + date.getDate(),
	        year = date.getFullYear();

	    if (month.length < 2) 
	        month = "0" + month;
	    if (day.length < 2) 
	        day = "0" + day;

	    return [year, month, day].join("-");
	}

	public static PROGRESS_BAR_COMPLETE_ZERO_WIDTH = "20px";
	public static PROGRESS_BAR_COMPLETE_FULL_WIDTH = "calc(100% + 2px)";

}


