import { Component } from "@angular/core";

import { Router } from "@angular/router"

import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";

import { TranslateService } from "@ngx-translate/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { PermissionStatus, PushNotifications } from "@capacitor/push-notifications";

import { UserService } from "./services/user.service";

@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
    styleUrls: ["app.component.css"]
})
export class AppComponent {

    constructor(
        private in_app_browser: InAppBrowser, 
        private platform: Platform, 
        private router: Router, 
        private splash_screen: SplashScreen,
        private statusBar: StatusBar, 
        private translate_service: TranslateService, 
        private user_service: UserService, 
    ) {
        this.initializeApp();
    }

    initializeApp() {

        let _this = this;

        // TODO: Remove this!
        this.translate_service.setDefaultLang("en-US");
        // this.translate_service.setDefaultLang(navigator.language);
        // this.translate_service.use("LANG_CODE");

        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splash_screen.hide();
        });

        this.router.navigate(["/"]);
        let destination = window.location.pathname;
        if (destination != "/") {
            this.user_service.load_logged_in_user().subscribe(
                user => {
                    if (user == null) {
                        // Do nothing.
                    } else {
                        this.router.navigate([destination]);
                    }
                }, 
                error => {
                    //
                }
            );
        }

        (window as any).open_external_global = function(url: string) {
            _this.in_app_browser.create(url, "_system")
        }

    }

}


