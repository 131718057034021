import { 
	Component, 
	ElementRef, 
	OnInit, 
	ViewChild, 
 } from "@angular/core";

 import { trigger, state, style, animate, transition } from "@angular/animations";

import { ModalController } from "@ionic/angular";

import { AppSettings } from "../app-settings";

import { ActionService } from "../services/action.service";
import { UserService } from "../services/user.service";

import { ActionDetailComponent } from "../action-detail/action-detail.component";
import { ActionDrawerComponent } from "../my-actions/action-drawer.component";
import { AddActionsPage } from "../add-actions/add-actions.page";
import { CommunityPage } from "../community/community.page";
import { FootprintPage } from "../footprint/footprint.page";
import { GamesPage } from "../games/games.page";

@Component({
	selector: "my-actions",
	templateUrl: "my-actions.page.html",
	styleUrls: ["my-actions.page.css"], 
	animations: [
	    trigger("drawer_state", [
	    	// state("up", style({height: '100%'})),
	    	state("up", style({height: 'calc(100% - 45px)'})),
	    	state("down", style({height: '142px'})),
			transition("up => down", animate("500ms ease")), 
			transition("down => up", animate("500ms ease")), 
	    ])
	], 
})
export class MyActionsPage implements OnInit {

	public static instance: MyActionsPage = null;

	private LOCALIZATION_PREFIX: string = "my_actions.";
	private title: string = this.LOCALIZATION_PREFIX + "title";

	private ONE_DAY: number = 1000 * 60 * 60 * 24;

	private active_date: Date = new Date();
	private subscribed_actions: Array<any> = null;
	private progress_bar_width: string = AppSettings.PROGRESS_BAR_COMPLETE_ZERO_WIDTH;

	private modal: any = null;

	@ViewChild("drawer_holder") drawer_holder: ElementRef;

	constructor(
		private action_service: ActionService, 
		private modal_controller: ModalController, 
		private user_service: UserService, 
	) {
		//
	}

	ngOnInit(): void {
		MyActionsPage.instance = this;

		this.reload_actions();
	}

	private get_language(): string {
		return navigator.language;
	}

	private get_action_content_width(): string {
		return (window.innerWidth - 90) + "px";
	}

	private get_num_points_str(action: any): string {
		return Math.round(action.num_points).toLocaleString();
	}

	// Most filters are server-side, but we do TZ-specific filters here:
	private get_filtered_subscribed_actions(): Array<any> {
		var actions = [];
		let now = new Date().getTime();
		this.subscribed_actions.forEach(action => {
			if (action.schedule_type == "one_time" && !action.completed) {
				let active_time = this.active_date.getTime();
				if (active_time <= now && active_time > now - 7 * this.ONE_DAY) actions.push(action);
			} else {
				actions.push(action);
			}
		});
		return actions;
	}

	private increment_active_date(): void {
		this.active_date = new Date(this.active_date.getTime() + this.ONE_DAY);
		this.reload_actions();
	}

	private decrement_active_date(): void {
		this.active_date = new Date(this.active_date.getTime() - this.ONE_DAY);
		this.reload_actions();
	}

	private is_active_date_today(): boolean {
		return AppSettings.format_date_for_SQL(this.active_date) == AppSettings.format_date_for_SQL(new Date());
	}
	private can_toggle_action_completed(action: any): boolean {
		if (this.active_date.getTime() > new Date().getTime()) return false;

		if (action.is_event == 1) {
			let date_diff_in_days = (new Date().getTime() - this.active_date.getTime()) / this.ONE_DAY;
			return date_diff_in_days <= 7;
		} else {
			return true;
		}
	}

	private get_num_completed_actions(): number {
		var num_completed = 0;
		this.subscribed_actions.forEach(action => {
			if (action.completed) num_completed ++;
		});
		return num_completed;
	}

	private compute_progress_bar_width(): string {
		if (this.subscribed_actions == null) return this.progress_bar_width;
		let num_completed_actions = this.get_num_completed_actions();
		let num_filtered_subscribed_actions = this.get_filtered_subscribed_actions().length;
		if (num_completed_actions == 0 || num_filtered_subscribed_actions == 0) {
			this.progress_bar_width = AppSettings.PROGRESS_BAR_COMPLETE_ZERO_WIDTH;
		} else if (num_completed_actions == num_filtered_subscribed_actions) {
			this.progress_bar_width = AppSettings.PROGRESS_BAR_COMPLETE_FULL_WIDTH;
		} else {
			this.progress_bar_width = Math.round(num_completed_actions / num_filtered_subscribed_actions * 100) + "%";
		}
		return this.progress_bar_width;
	}

	private toggle_action_completed(action: any): void {
		action.completed = !action.completed;
		if (action.completed) {
			this.user_service.get_logged_in_user().total_points += action.num_points;
		} else {
			this.user_service.get_logged_in_user().total_points -= action.num_points;
		}
		this.action_service.toggle_action_completed(action, AppSettings.format_date_for_SQL(this.active_date)).subscribe(
			success => {
				if (ActionDrawerComponent.instance) ActionDrawerComponent.instance.reload_action_library();
				if (AddActionsPage.instance) AddActionsPage.instance.reload_action_library();
				if (FootprintPage.instance) FootprintPage.instance.refresh_user();
				if (GamesPage.instance) GamesPage.instance.reload_games_data();
			}, 
			error => {
				//
			}
		);
	}

	async display_action_detail_modal(action: any) {
		this.modal = await this.modal_controller.create({
			component: ActionDetailComponent,
			componentProps: {
				action: action, 
		    	parent: this, 
		    }, 
			cssClass: "action_library", 
		});
		return await this.modal.present();
	}

	dismiss_modal(): void {
		if (this.modal) this.modal.dismiss().then(() => { this.modal = null; });
	}

	private drawer_state: string = "down";
	drawer_swipeup(): void {
		// console.log("drawer_swipeup");
		this.drawer_state = "up";
	}

	drawer_swipedown(): void {
		// console.log("drawer_swipedown");
		this.drawer_state = "down";
	}

	public reload_actions(): void {
		this.subscribed_actions = null;
		this.action_service.load_subscribed_actions(AppSettings.format_date_for_SQL(this.active_date)).subscribe(
			subscribed_actions => {
				this.subscribed_actions = subscribed_actions;
			}, 
			error => {
				//
			}
		);
	}

	toggle_action_liked(action: any): void {
		action.liked = !action.liked;
		this.action_service.toggle_action_liked(action).subscribe(
			success => {
				if (ActionDrawerComponent.instance) ActionDrawerComponent.instance.reload_action_library();
				if (AddActionsPage.instance) AddActionsPage.instance.reload_action_library();
				if (CommunityPage.instance) CommunityPage.instance.reload_action_library();
			}, 
			error => {
				//
			}
		);
	}

	toggle_action_subscribed(action: any, invoke_on_success: any): void {
		action.subscribed = !action.subscribed;
		this.action_service.toggle_action_subscribed(action).subscribe(
			success => {
				this.reload_actions();
				if (ActionDrawerComponent.instance) ActionDrawerComponent.instance.reload_action_library();
				if (AddActionsPage.instance) AddActionsPage.instance.reload_action_library();
				if (CommunityPage.instance) CommunityPage.instance.reload_action_library();
				if (invoke_on_success) invoke_on_success();
			}, 
			error => {
				//
			}
		);
	}

	notify_listeners(): void {
		this.reload_actions();
		if (ActionDrawerComponent.instance) ActionDrawerComponent.instance.reload_action_library();
		if (AddActionsPage.instance) AddActionsPage.instance.reload_action_library();
		if (CommunityPage.instance) CommunityPage.instance.reload_action_library();
	}

}


