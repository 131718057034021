import { 
	Component, 
	OnInit, 
	QueryList, 
	ViewChildren, 
} from "@angular/core";

import { trigger, state, style, animate, transition } from "@angular/animations";

import { ActivatedRoute, Router } from "@angular/router"

import { FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";

import { ItemReorderEventDetail } from "@ionic/core";

import { IonInput } from "@ionic/angular";

import { SurveyService } from "../services/survey.service";
import { UserService } from "../services/user.service";

import { ActionDrawerComponent } from "../my-actions/action-drawer.component";
import { AddActionsPage } from "../add-actions/add-actions.page";
import { FootprintPage } from "../footprint/footprint.page";
import { MyActionsPage } from "../my-actions/my-actions.page";

@Component({
	selector: "welcome",
	templateUrl: "./welcome.component.html",
	styleUrls: ["./welcome.component.css"], 
	animations: [
	    trigger("fade_state", [
	    	state("in", style({opacity: 1.0})),
	    	state("out", style({opacity: 0.0})),
			state("void", style({opacity: 0.0})),
			transition("void => *", animate("500ms linear")), 
			transition("* => void", animate("500ms linear")), 
			transition("in => out", animate("500ms linear")), 
			transition("out => in", animate("500ms linear")), 
	    ])
	]
})
export class WelcomeComponent implements OnInit {

	public static instance: WelcomeComponent = null;

	private PHASE_WELCOME_MESSAGE: string = "welcome_message";
	private PHASE_CREATE_ACCOUNT: string = "create_account";
	private PHASE_SURVEY: string = "survey";
	private PHASE_END: string = "end";
	private phase: string = null;

	private waiting_for_server_response: boolean = false;

	private email_control: FormControl = new FormControl("", [Validators.required, Validators.email]);
	private password_control = new FormControl("", [Validators.required, Validators.minLength(8)]);
	private password_match_validator(): ValidatorFn {
		return (password_2_control: FormControl) => {
			if (this.password_control.value == password_2_control.value) {
				return null;
			} else {
				return {matches: {dummy: true}};
			}
		}
	}
	private password_2_control = new FormControl("", [Validators.required, Validators.minLength(8), this.password_match_validator()]);
	private create_account_form: FormGroup = new FormGroup({
		email: this.email_control, 
		password: this.password_control, 
		password_2: this.password_2_control, 
	});
	private create_account_email: string = "";
	private create_account_password: string = "";
	private create_account_password_2: string = "";

	private survey: any = null;
	private active_section_name: string = null;
	private active_question: any = null;

	private LOCALIZATION_PREFIX: string = "welcome.";

	private should_display_user_data: boolean = false;

	constructor(
		private route: ActivatedRoute, 
		private router: Router, 
		private survey_service: SurveyService, 
		private user_service: UserService, 
	) {
		//
	}

	ngOnInit(): void {
		WelcomeComponent.instance = this;

		let _ = this.route.params.subscribe(params => {
			var section_index_to_clear;
			if (params.hasOwnProperty("section_index")) {
				section_index_to_clear = +(params.section_index.substring(1));
			} else {
				section_index_to_clear = -1;
			}
			this.survey_service.load_survey_and_clear_section(section_index_to_clear).subscribe(
				survey => {
					this.survey = survey;
					this.update_state();
				}, 
				error => {
					//
				}
			);
		});

	}

	private section_index_from_URL: number = null;
	private answered_question_IDs: Array<number> = [];
	// Checks for user state and URL param. Assumes the survey is loaded, which could possible be dangerous when called by an outside component.
	public update_state(): void {
		this.should_display_user_data = false;
		this.user_service.load_logged_in_user().subscribe(
			user => {
				if (user == null) {
					let _ = this.route.params.subscribe(params => {
						if (params.hasOwnProperty("section_index") && params.section_index == ":create-account") {
							this.phase = this.PHASE_CREATE_ACCOUNT;
						} else {
							this.phase = this.PHASE_WELCOME_MESSAGE;
						}
						this.should_display_user_data = true;
						this.active_section_name = this.survey.sections[0].name;
						this.active_question = this.survey.sections[0].questions[0];
					});
				} else {
					let _ = this.route.params.subscribe(params => {
						setTimeout(function() { _.unsubscribe(); }, 0);
						this.section_index_from_URL = null;
						if (params.hasOwnProperty("section_index")) this.section_index_from_URL = +(params.section_index.substring(1));
						if (user.has_finished_setup && this.section_index_from_URL == null) {
							if (FootprintPage.instance) FootprintPage.instance.refresh_user();
							this.router.navigate(["/tabs/footprint"]);
						} else {
							if (this.section_index_from_URL != null) {
								this.answered_question_IDs = [];
								this.active_section_name = this.survey.sections[this.section_index_from_URL].name;
								this.active_question = this.survey.sections[this.section_index_from_URL].questions[0];
							} else {
								this.active_section_name = this.survey.sections[0].name;
								this.active_question = this.survey.sections[0].questions[0];
							}
							this.phase = this.PHASE_SURVEY;
						}
						this.should_display_user_data = true;
					});
				}
			}, 
			error => {
				//
			}
		);
	}

	private finish_survey(): void {
		if (ActionDrawerComponent.instance) ActionDrawerComponent.instance.reload_action_library();
		if (AddActionsPage.instance) AddActionsPage.instance.reload_action_library();
		if (FootprintPage.instance) FootprintPage.instance.refresh_user();
		if (MyActionsPage.instance) MyActionsPage.instance.reload_actions();
		this.router.navigate(["/tabs/footprint"]);
	}

	private previous_question(): void {
		let search_id = this.active_question.id;
		for (var section_index = 0; section_index < this.survey.sections.length; section_index ++) {
			let section = this.survey.sections[section_index];
			for (var question_index = 0; question_index < section.questions.length; question_index ++) {
				let question = section.questions[question_index];
				if (question.id == search_id) return;
				if (question.conditional_on && question.conditional_on.trim() != "" && !this.check_condition(question.conditional_on)) continue;
				this.active_section_name = section.name;
				this.active_question = question;
			}
		}
	}
	private next_question(): void {

		var neighborhood_choices = [];

		// First process the answer:
		if (this.active_question.type == "number") {

			// Special case: zip codes
			if (this.active_question.id == 1612309184505) {
				var found = false;
				let zip = this.active_question.response;
				for (var city_index = 0; city_index < this.survey.cities.length; city_index ++) {
					let city = this.survey.cities[city_index];
					if (city.zips.includes(zip)) {
						this.survey_service.save_survey_response("city_id", city.id).subscribe(success => {}, error => {});
						city.neighborhoods.forEach(neighborhood => {
							neighborhood_choices.push({
								id: neighborhood.id, 
								title: neighborhood.name, 
							});
						});
						found = true;
						break;
					}
				}
				if (!found) {
					alert("Zip not found!");
					return;
				}
			}

			// TODO: Internationalize:
			this.survey_service.save_survey_response(this.active_question.id, this.active_question.response.replace(/[^\d\.]/g, "")).subscribe(success => {}, error => {});

		} else if (this.active_question.type == "choose_one") {

			this.survey_service.save_survey_response(this.active_question.id, this.active_question.response).subscribe(success => {}, error => {});

		} else if (this.active_question.type == "choose_multiple") {

			let answers = [];
			for (var i = 0; i < this.active_question.choices.length; i ++) {
				let choice = this.active_question.choices[i];
				if (choice.is_chosen) answers.push(choice.id);
			}
			this.survey_service.save_survey_response(this.active_question.id, JSON.stringify(answers)).subscribe(success => {}, error => {});

		} else if (this.active_question.type == "ranked") {

			let answers = [];
			for (var i = 0; i < this.active_question.choices.length; i ++) {
				let choice = this.active_question.choices[i];
				answers.push(choice.id);
			}
			this.survey_service.save_survey_response(this.active_question.id, JSON.stringify(answers)).subscribe(success => {}, error => {});

		} else if (this.active_question.type == "custom") {

			this.survey_service.save_survey_response(this.active_question.id, this.active_question.response).subscribe(success => {}, error => {});

		}

		this.answered_question_IDs.push(this.active_question.id);

		// Now navigate to the next question:
		var found = false;
		for (var section_index = 0; section_index < this.survey.sections.length; section_index ++) {
			if (this.section_index_from_URL == null) {
				if (section_index > 0) break;
			} else {
				if (section_index > this.section_index_from_URL) break;
			}
			let section = this.survey.sections[section_index];
			for (var question_index = 0; question_index < section.questions.length; question_index ++) {
				let question = section.questions[question_index];
				if (found) {
					if (question.conditional_on && question.conditional_on.trim() != "" && !this.check_condition(question.conditional_on)) continue;
					this.active_section_name = section.name;
					this.active_question = question;
					// Special case: neighborhoods
					if (this.active_question.id == 1610918565840) this.active_question.choices = neighborhood_choices;
					return;
				}
				if (question.id == this.active_question.id) found = true;
			}
		}
		this.transition_to_phase(this.PHASE_END);

	}
	private check_condition(_conditional_on: string): boolean {

		let conditional_on_parts = _conditional_on.trim().split(",");
		for (var co_index = 0; co_index < conditional_on_parts.length; co_index ++) {

			let conditional_on = conditional_on_parts[co_index].trim();

			for (var section_index = 0; section_index < this.survey.sections.length; section_index ++) {
				let section = this.survey.sections[section_index];
				for (var question_index = 0; question_index < section.questions.length; question_index ++) {
					let question = section.questions[question_index];

					if (this.section_index_from_URL == section_index && !this.answered_question_IDs.includes(question.id)) continue;

					// Check if the condition is a question:
					if (question.id == conditional_on) {
						if (question.type == "number" || question.type == "choose_one") {
							if (question.response && question.response != "") return true;
						}
					}

					// Check if the condition is a choice:
					if (question.type == "choose_one") {
						if (question.response == conditional_on) return true;
					} else if (question.type == "choose_multiple") {
						for (var choice_index = 0; choice_index < question.choices.length; choice_index ++) {
							let choice = question.choices[choice_index];
							if (choice.is_chosen && choice.id == conditional_on) return true;
						}
					}

				}
			}

		}

		return false;

	}

	private on_choices_reorder(ev: CustomEvent<ItemReorderEventDetail>, active_question: any) {
		active_question.choices = ev.detail.complete(active_question.choices);
	}

	private is_active_question_answered(): boolean {
		if (!this.active_question) return false;

		if (this.active_question.response) return true;

		if (this.active_question.type == "choose_multiple") {
			for (var i = 0; i < this.active_question.choices.length; i ++) {
				let choice = this.active_question.choices[i];
				if (choice.is_chosen) return true;
			}
		}

		if (this.active_question.type == "ranked") return true;

		return false;
	}

	private submit_create_account_form(): void {
		let email = this.email_control.value;
		let password = this.password_control.value;
		this.waiting_for_server_response = true;
		this.user_service.create_user(email, password).subscribe(
			success => {
				if (success) {
					this.user_service.log_in(email, password).subscribe(
						user => {
							this.waiting_for_server_response = false;
							if (user != null) this.transition_to_phase(this.PHASE_SURVEY);
						}, 
						error => {
							this.waiting_for_server_response = false;
						}
					);
				} else {
					this.waiting_for_server_response = false;
				}
			}, 
			error => {
				this.waiting_for_server_response = false;
			}
		);
	}

	private last_top_toolbal_title: string = "";
	private get_top_toolbar_title(): string {
		var top_toolbar_title;
		if (this.phase == this.PHASE_WELCOME_MESSAGE || this.phase == this.PHASE_CREATE_ACCOUNT) {
			top_toolbar_title = this.LOCALIZATION_PREFIX + "top_bar_create_account";
		} else if (this.phase == null) {
			top_toolbar_title = this.last_top_toolbal_title;
		} else if (this.phase == this.PHASE_SURVEY) {
			top_toolbar_title = this.active_section_name;
		} else if (this.phase == this.PHASE_END) {
			top_toolbar_title = this.LOCALIZATION_PREFIX + "top_bar_end_of_survey";
		}
		this.last_top_toolbal_title = top_toolbar_title;
		return top_toolbar_title
	}

	private transition_to_phase(next_phase: string) {
		this.phase = null;
		let _this = this;
		setTimeout(function() {
			_this.phase = next_phase;
		}, 600);
	}

}


