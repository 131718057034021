import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { Platform } from "@ionic/angular";

import { PermissionStatus, PushNotifications } from "@capacitor/push-notifications";

import { AuthInterceptor } from "../auth-interceptor";

import { AppSettings } from "../app-settings";

@Injectable()
export class UserService {

	private logged_in_user: any;

	constructor(
		private httpClient: HttpClient, 
		private platform: Platform, 
		private router: Router
	) { }

	get_logged_in_user(): any {
		return this.logged_in_user;
	}
	load_logged_in_user(): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-logged-in-user", 
			{withCredentials: true}
		).pipe(
			map(res => {
				this.logged_in_user = (res as any).user;
				return this.logged_in_user;
			}),
			catchError(error => {
				// console.log(error);
				// alert("An error occurred.");
				return null;
			})
		);
	}

	reset_user(): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "reset-user", 
			{withCredentials: true}
		).pipe(
			map(res => {
				return (res as any).success;
			}),
			catchError(error => {
				// alert("An error occurred.");
				return null;
			})
		);
	}

	create_user(email: string, password: string): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "headless-create", 
			JSON.stringify({email: email, password: password}), 
			{headers: AppSettings.POST_HEADERS}
		).pipe(
			map(res => {
				if (!(res as any).success) alert((res as any).message);
				return (res as any).success;
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

	private save_push_token(push_token_ios: string, push_token_android: string): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "save-push-token", 
			JSON.stringify({push_token_ios: push_token_ios, push_token_android: push_token_android}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				return true;
			}),
			catchError(error => {
				// console.log(error);
				// alert("An error occurred.");
				return null;
			})
		);
	}

	log_in(email: string, password: string): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "headless-login", 
			JSON.stringify({email: email, password: password}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {

				let _this = this;
				_this.platform.ready().then(() => {
					if (_this.platform.platforms().includes("ios") && !_this.platform.platforms().includes("desktop")) { // TODO: or Android
			            PushNotifications.requestPermissions().then((status: PermissionStatus) => {
			                if (status.receive == "granted") {
			                    PushNotifications.addListener("registration", function(token: any) {
			                        if (_this.platform.platforms().includes("ios")) {
			                            console.log("iOS push token: " + token.value);
			                            _this.save_push_token(token.value, null).subscribe(
											success => {
												// 
											}, 
											error => {
												// console.log(error);
											}
										);
			                        } else {
			                            console.log("Android push token: " + token);
			                        }
			                    });
			                    PushNotifications.register().then();
			                }
			            });
			        }
		        });

				AuthInterceptor.Green = (res as any).Green;
				this.logged_in_user = (res as any).user;
				return this.logged_in_user;
			}),
			catchError(error => {
				// alert("An error occurred.");
				return null;
			})
		);
	}

	log_out(): Observable<any> {
		AuthInterceptor.Green = "";
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "headless-logout", 
			{}, 
			{withCredentials: true}
		).pipe(
			map(res => {
				let success = (res as any).success;
				if (success) this.logged_in_user = null;
				return success;
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

	request_password_reset(email: string): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "headless-reset", 
			{email: email}, 
			{headers: AppSettings.POST_HEADERS}
		).pipe(
			map(res => {
				return (res as any).success;
			}),
			catchError(error => {
				// alert("An error occurred.");
				return null;
			})
		);
	}

}


